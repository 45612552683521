html {
  height: 100%;
}

body {
  color: #4f7fa3;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

select:focus,
select:hover {
  outline: none !important;
}

input:focus,
input:hover {
  box-shadow: none !important;
  outline: none;
}

button:focus,
button:hover {
  box-shadow: none !important;
  outline: none;
}

button[disabled] {
  opacity: 1 !important;
}

.navbar__link--active {
  font-weight: bold;
}
